import React from 'react';
import Button from "@material-ui/core/Button";

function SimpleButton(props) {
    const { onClick, color, text } = props;
    return (
        <div>
            <Button variant="contained" color={color} onClick={onClick}>
                {text}
            </Button>
        </div>
    )
}

export default SimpleButton
