import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { positions, Provider } from 'react-alert';
import reportWebVitals from './reportWebVitals';

const options = {
  timeout: 3000,
  position: positions.BOTTOM_CENTER,
};

const AlertTemplate = ({ style, options, message, close }) => (
  <div
    className={`edenAlert ${options.type === 'success' ? 'edenAlertSuccess' : 'edenAlertError'
      }`}
    style={style}>
    <div></div>
    {message}
    <span className='alertCancel' onClick={close}>X</span>
  </div>
);

ReactDOM.render(
  <Provider template={AlertTemplate} {...options}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
