import React, { useState, useEffect } from 'react';
import Table from '../../components/Table/Table';
import { useAlert } from 'react-alert';
import Loader from '../../components/Loader/Loader';
import ApiRequest from '../../services/apiRequest';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Modal from 'react-modal';
import ButtonComponent from '../../components/Button/Button';
import './Bookings.css';

export const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        padding: '1.875rem',
        right: 'auto',
        width: Boolean(window.navigator.userAgent.match(
            /Mobi|Android|webOS|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i
        )) ? '24rem' : '30rem',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: '3px 4px 27px rgba(0, 0, 0, 0.07)',
        border: '',
    },
};



function Bookings() {
    const [loading, setLoading] = useState(true);
    const [allData, setAllData] = useState({});
    const [bookingId, setBookingId] = useState(null);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [refetch, setRefetch] = useState(false);

    const alert = useAlert();

    useEffect(() => {
        fetchBookingData();
         //eslint-disable-next-line
    }, [refetch]);

    const showModal = (id) => {
        setBookingId(id);
        setIsDeleteOpen(true)
    }

    function closeModal() {
        setIsDeleteOpen(false)
    }

    const columns = [
        { field: 'transaction_id', headerName: 'BOOKING ID', cellClassName: 'bookin-id', width: 250 },
        { field: 'hotel_name', headerName: 'HOTEL NAME', width: 250 },
        { field: 'booked_date', headerName: 'BOOKED DATE/TIME', width: 200 },
        {
            field: 'duration',
            headerName: 'DURATION',
            type: 'number',
            width: 130,
        },
        {
            field: 'guest_name',
            headerName: 'GUEST NAME',
            width: 160,
        },
        { field: 'payment_base_amount', headerName: 'BASE PRICE', width: 130 },
        { field: 'payment_amount_charged', headerName: 'AMOUNT PAID', width: 130 },
        {
            field: 'profit', headerName: 'PROFIT', width: 130 
               
        },
        { field: 'payment_reference', headerName: 'REFERENCE', width: 130 },
        { field: 'cancel', headerName: 'ACTION', width: 250, renderCell: (params) => {
            if (params.row.cancelled !== true) {
                return <Button
                    variant="contained"
                    color="secondary"
                    size="medium"
                    onClick={() => showModal(params.row.transaction_id)}
                    style={{ marginLeft: 16 }}
                    >
                    Cancel
                </Button>
            } else {
                return <Button
                    variant="contained"
                    color="default"
                    size="medium"
                    style={{ marginLeft: 16 }}
                    >
                    Cancelled
                </Button>
            }
           
        } },
    ];


    const fetchBookingData = async () => {
        try {
            let response = await ApiRequest.fetchBookingData();
            const { data } = response;

            if (data.data) {
                let count = 1;
                data.data.bookings.forEach((booking) => { booking.id = count; booking.profit = booking.payment_amount_charged - booking.payment_base_amount; count++ } )
                setAllData(data.data.bookings);
            }
            setLoading(false)
        } catch (error) {
            if (error && error.response) {
                alert.error(error.response.data.validations);
                setLoading(false);
            }
        }
    }

    const handleCancelSubmit = async () => {
        const build = {
            id: bookingId
        }

        try {
            setModalLoading(true);
            let response = await ApiRequest.cancelBooking(build);
            const { data } = response;

            if (data.booking) {
                alert.success('Booking has been successfully cancelled');
                setIsDeleteOpen(false);
                setRefetch(!refetch);
            }

            setModalLoading(false)
        } catch (error) {
            if (error && error.response) {
                alert.error(error.response.data.validations);
                setModalLoading(false);
            }
        }
    }

    return (

        <div>
            {loading ? <Loader /> : <Table columns={columns} rows={allData} /> }
            <Modal
                style={customStyles}
                isOpen={isDeleteOpen}
                onRequestClose={closeModal}
                contentLabel='Example Modal'>
                <div className='settingsSpacing'>
                    <Typography variant="h5" component="h2" >
                        Delete Booking
                    </Typography>
                </div>
                <div className='settingsSpacing'>
                    <Typography variant="subtitle1" className='settingsSpacing'>
                        Are you sure you want to delete booking with the id {bookingId} ?
                    </Typography>
                </div>
                <div className='serviceChargeButtonContainers'>
                    <div className='settingsSubmitButton'>
                        <Button
                            onClick={handleCancelSubmit}
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={modalLoading}
                        >
                            Confirm
                            {modalLoading ? <CircularProgress size={20} /> : ''}

                        </Button>
                    </div>
                    <div>
                        <ButtonComponent text={'Cancel'} onClick={closeModal} />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Bookings
