
require('dotenv').config();

export const resolveBaseUrl = () => {
    // const url = process.env.REACT_APP_API_URL || 'https://quikstays-admin-ng.azurewebsites.net';
    const url = process.env.REACT_APP_API_URL || 'https://quikstays-admin-ng-prd.azurewebsites.net';
    const baseUrl = `${url}/api/v1`;

    return baseUrl;
};

export const getUserToken = () => localStorage.getItem('QuikstaysAdminToken');

export const config = {
    headers: {
        'x-access-token': getUserToken(),
    },
};
