import axios from 'axios';
import { resolveBaseUrl, getUserToken } from '.';

const baseUrl = resolveBaseUrl();

class ApiRequest {
    static loginUser(userData) {
        return axios.post(`${baseUrl}/login`, userData);
    }
    static fetchDashboardData() {
        return axios.get(`${baseUrl}/dashboard`, {
            headers: {
                'Authorization': `Bearer ${getUserToken()} `,
            },
        });
    }
    static fetchBanks() {
        return axios.get('https://api.paystack.co/bank');
    }
    static fetchBookingData() {
        return axios.get(`${baseUrl}/get-bookings`, {
            headers: {
                'Authorization': `Bearer ${getUserToken()} `,
            },
        });
    }
    static fetchHotelData() {
        return axios.get(`${baseUrl}/get-hotels`, {
            headers: {
                'Authorization': `Bearer ${getUserToken()} `,
            },
        });
    }
    static fetchAdminData() {
        return axios.get(`${baseUrl}/admin`, {
            headers: {
                'Authorization': `Bearer ${getUserToken()} `,
            },
        });
    }
    static fetchPartnerData() {
        return axios.get(`${baseUrl}/partner`, {
            headers: {
                'Authorization': `Bearer ${getUserToken()} `,
            },
        });
    }
    static fetchDiscountData() {
        return axios.get(`${baseUrl}/discounts`, {
            headers: {
                'Authorization': `Bearer ${getUserToken()} `,
            },
        });
    }
    static fetchEnquiriesData() {
        return axios.get(`${baseUrl}/get-enquiries`, {
            headers: {
                'Authorization': `Bearer ${getUserToken()} `,
            },
        });
    }
    static fetchSettingsData() {
        return axios.get(`${baseUrl}/settings`, {
            headers: {
                'Authorization': `Bearer ${getUserToken()} `,
            },
        });
    }
    static setSettingsData(serviceCharge) {
        return axios.post(`${baseUrl}/settings`, { serviceCharge }, {
            headers: {
                'Authorization': `Bearer ${getUserToken()} `,
            },
        });
    }
    static createAdmin(data) {
        return axios.post(`${baseUrl}/admin-creation`, data, {
            headers: {
                'Authorization': `Bearer ${getUserToken()} `,
            },
        });
    }
    static createDiscount(data) {
        return axios.post(`${baseUrl}/discounts`, data, {
            headers: {
                'Authorization': `Bearer ${getUserToken()} `,
            },
        });
    }
    static createPartner(data) {
        return axios.post(`${baseUrl}/partner-creation`, data, {
            headers: {
                'Authorization': `Bearer ${getUserToken()} `,
            },
        });
    }
    static deleteDiscount(data) {
        return axios.post(`${baseUrl}/delete-discount`, data, {
            headers: {
                'Authorization': `Bearer ${getUserToken()} `,
            },
        });
    }
    static deleteAdmin(data) {
        return axios.post(`${baseUrl}/delete-admin`, data, {
            headers: {
                'Authorization': `Bearer ${getUserToken()} `,
            },
        });
    }
    static toggleHotel(data) {
        return axios.post(`${baseUrl}/toggle-hotel`, data, {
            headers: {
                'Authorization': `Bearer ${getUserToken()} `,
            },
        });
    }
    static changePartnerPassword(data) {
        return axios.post(`${baseUrl}/update-partner`, data, {
            headers: {
                'Authorization': `Bearer ${getUserToken()} `,
            },
        });
    }
    static updateHotelAccount(data, id) {
        return axios.post(`${baseUrl}/update-hotel/${id}`, data, {
            headers: {
                'Authorization': `Bearer ${getUserToken()} `,
            },
        });
    }
    static cancelBooking(data) {
        return axios.post(`${baseUrl}/cancel-booking/${data.id}`, {}, {
            headers: {
                'Authorization': `Bearer ${getUserToken()} `,
            },
        });
    }
}

export default ApiRequest;
