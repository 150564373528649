import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ReactHtmlParser from 'react-html-parser';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

export default function SimpleCard(props) {
    const { text, digit, symbol } = props;
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography variant="h5" component="h2">
                    {symbol ? ReactHtmlParser('&#8358;')	 : '' } {digit}
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                    {text}
                </Typography>
            </CardContent>
        </Card>
    );
}
