import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import './Settings.css';
import Button from "@material-ui/core/Button";
import { useAlert } from 'react-alert';
import Typography from '@material-ui/core/Typography';
import ButtonComponent from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import ApiRequest from '../../services/apiRequest';
import Modal from 'react-modal';
import CircularProgress from '@material-ui/core/CircularProgress';

export const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        padding: '1.875rem',
        right: 'auto',
        width: Boolean(window.navigator.userAgent.match(
            /Mobi|Android|webOS|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i
        )) ? '24rem' : '30rem',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: '3px 4px 27px rgba(0, 0, 0, 0.07)',
        border: '',
    },
};

Modal.setAppElement('#root')

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '25ch',
    },
}));

function Settings() {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [charge, setCharge] = useState(0);
    const [chargeEdit, setChargeEdit] = useState(0);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);

    function closeModal() {
        setIsOpen(false);
    }

    const alert = useAlert();

    useEffect(() => {
        fetchSettingsData();
         //eslint-disable-next-line
    }, []);

    const openModal = () => {
        if (chargeEdit === 0) {
            alert.error('Service charge cannot be 0');
            return;
        }

        if (chargeEdit === charge) {
            return;
        }

        setIsOpen(true);
    }

    const fetchSettingsData = async () => {
        try {
            let response = await ApiRequest.fetchSettingsData();
            const { data } = response;

            if (data.setting) {
                setCharge(data.setting.serviceCharge);
                setChargeEdit(data.setting.serviceCharge);
            }
            setLoading(false)
        } catch (error) {
            if (error && error.response) {
                alert.error(error.response.data.validations);
                setLoading(false);
            }
        }
    }

    const handleSubmit = async () => {
        try {
            setModalLoading(true);
            let response = await ApiRequest.setSettingsData(chargeEdit);
            const { data } = response;

            if (data.message) {
                alert.success(data.message);
            }
            setModalLoading(false)
        } catch (error) {
            if (error && error.response) {
                alert.error(error.response.data.validations);
                setModalLoading(false);
            }
        }
    }

    return (
        <>
            { loading ? <Loader /> : <div className='settings'>
                <div className='settingsSpacing'>
                    <Typography variant="h6" component="h2" >
                        Percentage Settings
                    </Typography>
                </div>
                <div className='settingsSpacing'>
                    <TextField
                        onChange={(e) => setChargeEdit(e.target.value)}
                        type="number"
                        label="Present Percentage"
                        value={chargeEdit}
                        id="outlined-start-adornment"
                        className={clsx(classes.margin, classes.textField)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                        variant="outlined"
                    />
                </div>
                <div className='settingsButton'>
                    <ButtonComponent text={'Update'} color='primary' onClick={openModal} />
                </div>

            </div>}
            <Modal
                style={customStyles}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel='Example Modal'>
                <div className='settingsSpacing'>
                    <Typography variant="h5" component="h2" >
                        Update Service Charge
                    </Typography>
                </div>
                <div className='settingsSpacing'>
                    <Typography variant="subtitle1" className='settingsSpacing'>
                        Are you sure you want to change service charge from {charge}% to {chargeEdit}%? This would affect all future bookings
                    </Typography>
                </div>
                <div className='serviceChargeButtonContainers'>
                    <div className='settingsSubmitButton'>
                        <Button
                            onClick={handleSubmit}
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={modalLoading}
                        >
                            Confirm
                            {modalLoading ? <CircularProgress size={20} /> : ''}

                        </Button>
                    </div>
                    <div>
                        <ButtonComponent text={'Cancel'} onClick={closeModal} />
                    </div>
                </div>
            </Modal>

        </>
    )
}

export default Settings