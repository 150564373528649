import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import QuikStaysLogo from '../../assets/images/logo.png';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { validateEmail } from '../../utils/utils';
import ApiRequest from '../../services/apiRequest';
import './Login.css';

function SignIn() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const history = useHistory();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleSubmit = async () => {
        setIsLoading(true);

        if (!email) {
            alert.error('Email field can not be empty');
            setIsLoading(false);
            return;
        }

        if (!password) {
            alert.error('Password field can not be empty');
            setIsLoading(false);
            return;
        }

        if (!validateEmail(email)) {
            alert.error('Please enter a correct email format');
            setIsLoading(false);
            return;
        }

        let userData = {
            email,
            password,
        };

        try {
            let response = await ApiRequest.loginUser(userData);
            const { data } = response;
            const { token } = data;

            if (token) {
                await localStorage.setItem('QuikstaysAdminToken', token);
                setIsLoading(false);
                history.replace('/');
            }
        } catch (error) {
            if (error && error.response) {
                alert.error(error.response.data.validations);
                setIsLoading(false);
            }
        }

    }

    return (
        <div className="container">
            <div className='loginWrapper'>
                <div >
                    <div className="text-center spacingDown">
                        <img src={QuikStaysLogo} className="img-fluid" style={{ height: '60px' }} alt="quikstays-logo" />
                    </div>
                    <div className="form-group mb-3 spacingDown">
                        <TextField
                            onChange={(e) => setEmail(e.target.value)}
                            variant="outlined"
                            label="Email"
                            id="email"
                            value={email}
                            className="d-block"
                            fullWidth
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className="form-group mb-3 spacingDown">
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <OutlinedInput
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            // onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                        </FormControl>
                    </div>
                    <div className="form-group">
                        <Button
                            onClick={handleSubmit}
                            type="submit"
                            className="mt-2 primaryColor"
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="large"
                            disabled={isLoading}
                        >
                            {isLoading ? <CircularProgress size={20} /> : 'Login'}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignIn;