import React, { useState, useEffect } from 'react';
import Table from '../../components/Table/Table';
import { useAlert } from 'react-alert';
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography';
import Loader from '../../components/Loader/Loader';
import ApiRequest from '../../services/apiRequest';
import ButtonComponent from '../../components/Button/Button';
import Modal from 'react-modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import './Discounts.css';

export const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        padding: '1.875rem',
        right: 'auto',
        width: Boolean(window.navigator.userAgent.match(
            /Mobi|Android|webOS|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i
        )) ? '24rem' : '30rem',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: '3px 4px 27px rgba(0, 0, 0, 0.07)',
        border: '',
    },
};

function Discounts() {
    const [loading, setLoading] = useState(true);
    const [allData, setAllData] = useState({});
    const [limit, setLimit] = useState('');
    const [code, setCode] = useState('');
    const [percentage, setPercentage] = useState('');
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [refetch, setRefetch] = useState(false);
    const [discount, setDiscount] = useState({});
    const [modalDeleteIsOpen, setIsDeleteOpen] = useState(false);

    const columns = [
        { field: 'code', headerName: 'CODE', width: 250 },
        { field: 'percentage', headerName: 'PERCENTAGE', width: 250 },
        { field: 'limit', headerName: 'LIMIT', width: 200 },
        { field: 'count', headerName: 'PRESENT COUNT', width: 200 },
        {
            field: 'action', headerName: 'ACTION', width: 200, renderCell: (params) => {
                return <Button
                    variant="contained"
                    color="secondary"
                    size="medium"
                    onClick={() => showModal(params.row)}
                    style={{ marginLeft: 16 }}
                >
                    Delete
                </Button>
            }
        },
    ];

    function closeModal() {
        setIsOpen(false);
        setIsDeleteOpen(false);
    }

    const showModal = (row) => {
        setDiscount(row);
        setIsDeleteOpen(true)
    }

    const alert = useAlert();

    useEffect(() => {
        fetchDiscountData();
         //eslint-disable-next-line
    }, [refetch]);

    const fetchDiscountData = async () => {
        try {
            let response = await ApiRequest.fetchDiscountData();
            const { data } = response;

            if (data.data) {
                data.data.discounts.forEach((discount) => { discount.id = discount._id; })
                setAllData(data.data.discounts);
            }
            setLoading(false)
        } catch (error) {
            if (error && error.response) {
                alert.error(error.response.data.validations);
                setLoading(false);
            }
        }
    }

    const handleSubmit = async () => {
        if (code === '' || code === undefined) {
            alert.error('Code field cannot be empty');
            return;
        }

        if (percentage === '' || percentage === undefined) {
            alert.error('Percentage field cannot be empty');
            return;
        }

        if (limit === '' || limit === undefined) {
            alert.error('Limit field cannot be empty');
            return;
        }

        let Data = {
            code,
            percentage,
            limit
        };

        setModalLoading(true);

        try {
            let response = await ApiRequest.createDiscount(Data);
            const { data } = response;
            const { message } = data;

            if (message) {
                alert.success('Discount creation successful');
                setModalLoading(false);
                setLimit('');
                setCode('');
                setPercentage('');
                setIsOpen(false);
                setRefetch(!refetch);
            }
        } catch (error) {
            if (error && error.response) {
                alert.error(error.response.data.validations);
                setModalLoading(false);
            }
        }

    }

    const handleDeleteSubmit = async () => {
        const build = {
            id: discount._id
        }

        try {
            setModalLoading(true);
            let response = await ApiRequest.deleteDiscount(build);
            const { data } = response;

            if (data.message) {
                alert.success(data.message);
                setIsDeleteOpen(false);
                setRefetch(!refetch);
            }

            setModalLoading(false)
        } catch (error) {
            if (error && error.response) {
                alert.error(error.response.data.validations);
                setModalLoading(false);
            }
        }
    }

    return (

        <div>
            {loading ? <Loader /> : <div>
                <div className='discountButtonContainer'>
                    <ButtonComponent text={'Create New Discount'} color='primary' onClick={() => setIsOpen(true)}/>
                </div>
                <Table columns={columns} rows={allData} />
            </div>}
            <Modal
                style={customStyles}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel='Example Modal'>
                <div className='settingsSpacing'>
                    <Typography variant="h5" component="h2" >
                        Add new Admin
                    </Typography>
                </div>
                <div className='settingsSpacing'>
                    <div className='settingsSpacing'>
                        <FormControl fullWidth>
                            <TextField onChange={(e) => setCode(e.target.value)} id="outlined-basic" label="Code" variant="outlined" />
                        </FormControl>
                    </div>
                    <div className='settingsSpacing'>
                        <FormControl fullWidth >
                            <TextField onChange={(e) => setPercentage(e.target.value)} id="outlined-basic" label="Percentage off" variant="outlined" type='number' />
                        </FormControl>
                    </div>
                    <div className='settingsSpacing'>
                        <FormControl fullWidth >
                            <TextField onChange={(e) => setLimit(e.target.value)} id="outlined-basic" label="Limit" variant="outlined" type='number' />
                        </FormControl>
                    </div>
                </div>
                <div className='serviceChargeButtonContainers'>
                    <div className='settingsSubmitButton'>
                        <Button
                            onClick={handleSubmit}
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={modalLoading}
                        >
                            Confirm
                            {modalLoading ? <CircularProgress size={20} /> : ''}

                        </Button>
                    </div>
                    <div>
                        <ButtonComponent text={'Cancel'} onClick={closeModal} />
                    </div>
                </div>
            </Modal>
            <Modal
                style={customStyles}
                isOpen={modalDeleteIsOpen}
                onRequestClose={closeModal}
                contentLabel='Example Modal'>
                <div className='settingsSpacing'>
                    <Typography variant="h5" component="h2" >
                        Delete Discount
                    </Typography>
                </div>
                <div className='settingsSpacing'>
                    <Typography variant="subtitle1" className='settingsSpacing'>
                        Are you sure you want to delete discount with the code {discount.code} ?
                    </Typography>
                </div>
                <div className='serviceChargeButtonContainers'>
                    <div className='settingsSubmitButton'>
                        <Button
                            onClick={handleDeleteSubmit}
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={modalLoading}
                        >
                            Confirm
                            {modalLoading ? <CircularProgress size={20} /> : ''}

                        </Button>
                    </div>
                    <div>
                        <ButtonComponent text={'Cancel'} onClick={closeModal} />
                    </div>
                </div>
            </Modal>

        </div>
    )
}

export default Discounts
