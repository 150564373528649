// react libraries
import React from 'react';
import Navbar from './components/Navbar/Navbar';
import { makeStyles } from '@material-ui/core/styles';

// third party library
import { Route, Redirect } from 'react-router-dom';
import Auth from './utils/tokenVerify';

/**
 * @desc renders header with links
 * @returns authenticated routes
 */

const useStyles = makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }));

function AuthenticatedRoute({
    component: Component,
    ...rest
}) {
    const classes = useStyles();

    return (
        <>
            <Route
                {...rest}
                render={(props) => {
                    return Auth.verifyUserToken(localStorage.getItem('QuikstaysAdminToken')) ? (
                        <Navbar>
                            <main className={classes.content}>
                            <div className={classes.toolbar} />
                            <Component
                                {...props}
                                from={props.location}
                            />
                            </main>
                        </Navbar>
                    ) : (
                            <Redirect
                                to={{
                                    pathname: '/login',
                                    state: { from: props.location },
                                }}
                            />
                        );
                }}
            />
        </>
    );
};

export default AuthenticatedRoute;
