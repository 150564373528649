import React, { useEffect, useState } from 'react';
import Card from '../../components/Card/Card';
import './Dashboard.css';
import { useAlert } from 'react-alert';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Loader from '../../components/Loader/Loader';
import ApiRequest from '../../services/apiRequest';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));


export default function CenteredGrid() {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [allData, setAllData] = useState({});
    const alert = useAlert();

    useEffect(() => {
        fetchDashboardData();
         //eslint-disable-next-line
    }, []);

    const fetchDashboardData = async () => {
        try {
            let response = await ApiRequest.fetchDashboardData();
            const { data } = response;

            if (data.data) {
                setAllData(data.data);
            }
            setLoading(false)
        } catch (error) {
            if (error && error.response) {
                alert.error(error.response.data.validations);
                setLoading(false);
            }
        }
    }

    return (
        <div className={classes.root}>
            {loading ? <Loader /> : <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Card digit={allData.enquiries_count} text={`Total Pending Enquiries`} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card digit={allData.todays_booking} text={`Today's Bookings`}  />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card digit={allData.todays_sale} text={`Today's Sale`} symbol/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card digit={allData.todays_profit} text={`Today's Profit`} symbol/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card digit={allData.todays_onboard} text={`Today's Hotel Onboard`} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card digit={allData.weeks_booking} text={`This week's Bookings`} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card digit={allData.weeks_sale} text={`This week's Sale`} symbol/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card digit={allData.weeks_profit} text={`This week's Profit`} symbol/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card digit={allData.weeks_onboard} text={`This week's Hotel Onboard`} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card digit={allData.months_booking} text={`This month's Bookings`} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card digit={allData.months_sale} text={`This month's Sale`} symbol/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card digit={allData.months_profit} text={`This month's Profit`} symbol/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card digit={allData.months_onboard} text={`This month's Hotel Onboarded`} />
                </Grid>
            </Grid>
            }
        </div>
    );
}