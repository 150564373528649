import React, { useState, useEffect } from 'react';
import Table from '../../components/Table/Table';
import { useAlert } from 'react-alert';
import Loader from '../../components/Loader/Loader';
import ApiRequest from '../../services/apiRequest';
import Button from "@material-ui/core/Button";
import ButtonComponent from '../../components/Button/Button';
import Modal from 'react-modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

export const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        padding: '1.875rem',
        right: 'auto',
        width: Boolean(window.navigator.userAgent.match(
            /Mobi|Android|webOS|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i
        )) ? '24rem' : '30rem',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: '3px 4px 27px rgba(0, 0, 0, 0.07)',
        border: '',
    },
};

function Partners() {
    const [loading, setLoading] = useState(true);
    const [allData, setAllData] = useState({});
    const [refetch, setRefetch] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [partner, setPartner] = useState({});

    const alert = useAlert();

    useEffect(() => {
        fetchPartnerData();
         //eslint-disable-next-line
    }, [refetch]);

    const showModal = (row) => {
        setPartner(row);
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const handleSubmit = async () => {

        if(password === '' || password === undefined) {
            alert.error('Password field cannot be empty');
            return;
        }

        if(confirmPassword === '' || confirmPassword === undefined) {
            alert.error('Confirm Password field cannot be empty');
            return;
        }

        if(password !== confirmPassword) {
            alert.error('Passwords do not match');
            return;
        }

        const build = {
            password,
            partner_id: partner._id
        }

        try {
            setModalLoading(true);
            let response = await ApiRequest.changePartnerPassword(build);
            const { data } = response;

            if (data.message) {
                alert.success(data.message);
                setIsOpen(false);
                setRefetch(!refetch);
            }

            setModalLoading(false)
        } catch (error) {
            if (error && error.response) {
                alert.error(error.response.data.validations);
                setModalLoading(false);
            }
        }
    }

    const columns = [
        { field: 'hotel', headerName: 'HOTEL ID', width: 250 },
        { field: 'email', headerName: 'PARTNER EMAIL', width: 250 },
        {
            field: 'action', headerName: 'ACTION', width: 250, renderCell: (params) => {
                return <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={() => showModal(params.row)}
                style={{ marginLeft: 16 }}
            >
                CHANGE PASSWORD
            </Button>
            }
        },
    ];

    const fetchPartnerData = async () => {
        try {
            let response = await ApiRequest.fetchPartnerData();
            const { data } = response;

            if (data.data) {
                data.data.partners.forEach((partner) => {
                    partner.id = partner._id;
                })
                setAllData(data.data.partners);
            }
            setLoading(false)
        } catch (error) {
            if (error && error.response) {
                alert.error(error.response.data.validations);
                setLoading(false);
            }
        }
    }

    return (
        <div>
            { loading ? <Loader /> : <div>
                <Table columns={columns} rows={allData} />
                <Modal
                    style={customStyles}
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel='Example Modal'>
                    <div className='settingsSpacing'>
                        <Typography variant="h5" component="h2" >
                            Change Partner Password
                    </Typography>
                    </div>
                    <div className='settingsSpacing'>
                    <div className='settingsSpacing'>
                        <FormControl fullWidth>
                            <TextField onChange={(e) => setPassword(e.target.value)} id="outlined-basic" label="New password" variant="outlined" type='password' />
                        </FormControl>
                    </div>
                    <div className='settingsSpacing'>
                        <FormControl fullWidth >
                            <TextField onChange={(e) => setConfirmPassword(e.target.value)} id="outlined-basic" label="Confirm password" variant="outlined" type='password' />
                        </FormControl>
                    </div>
                </div>
                    <div className='serviceChargeButtonContainers'>
                        <div className='settingsSubmitButton'>
                            <Button
                                onClick={handleSubmit}
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={modalLoading}
                            >
                                Confirm
                            {modalLoading ? <CircularProgress size={20} /> : ''}

                            </Button>
                        </div>
                        <div>
                            <ButtonComponent text={'Cancel'} onClick={closeModal} />
                        </div>
                    </div>
                </Modal></div>}
        </div>
    )
}

export default Partners
