import React, { useState, useEffect } from 'react';
import Table from '../../components/Table/Table';
import { useAlert } from 'react-alert';
import Loader from '../../components/Loader/Loader';
import ApiRequest from '../../services/apiRequest';
import Button from "@material-ui/core/Button";
import {  withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import ButtonComponent from '../../components/Button/Button';
import Typography from '@material-ui/core/Typography';
import Modal from 'react-modal';
import CircularProgress from '@material-ui/core/CircularProgress';

const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
}))(Button);

export const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        padding: '1.875rem',
        right: 'auto',
        width: Boolean(window.navigator.userAgent.match(
            /Mobi|Android|webOS|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i
        )) ? '24rem' : '30rem',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: '3px 4px 27px rgba(0, 0, 0, 0.07)',
        border: '',
    },
};


function Enquiries() {
    const [loading, setLoading] = useState(true);
    const [allData, setAllData] = useState({});
    const [hotel, setHotel] = useState({});
    const [refetch, setRefetch] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);

    function closeModal() {
        setIsOpen(false);
    }

    const showModal = (hotel) => {
        setHotel(hotel);
        setIsOpen(true);
    }

    const handleSubmit = async () => {
        const build = {
            email: hotel.hotelEmail,
            enquiry_id: hotel._id
        }

        try {
            setModalLoading(true);
            let response = await ApiRequest.createPartner(build);
            const { data } = response;

            if (data.message) {
                alert.success(data.message);
                setIsOpen(false);
                setRefetch(!refetch);
            }

            setModalLoading(false)
        } catch (error) {
            if (error && error.response) {
                alert.error(error.response.data.validations);
                setModalLoading(false);
            }
        }
    }

    const columns = [
        { field: 'contactName', headerName: 'CONTACT NAME', width: 250 },
        { field: 'contactEmail', headerName: 'CONTACT EMAIL', width: 200 },
        { field: 'hotelName', headerName: 'HOTEL NAME', width: 200 },
        {
            field: 'hotelEmail',
            headerName: 'HOTEL EMAIL',
            width: 200,
        },
        {
            field: 'hotelAddress',
            headerName: 'HOTEL ADDRESS',
            width: 300,
        },
        { field: 'hotelPhone', headerName: 'HOTEL PHONE', width: 200 },
        { field: 'hotelWebsite', headerName: 'HOTEL WEBSITE', width: 300 },
        {
            field: 'status', headerName: 'STATUS', width: 200, renderCell: (params) => {

                if (params.value === 'approved') {
                    return <ColorButton
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginLeft: 16 }}
                    >
                        Approved
                        </ColorButton>
                } else {
                    return <Button
                        variant="contained"
                        color="default"
                        size="small"
                        onClick={() => showModal(params.row)}
                        style={{ marginLeft: 16 }}
                    >
                        Approve
                        </Button>
                }
            }
        },
    ];

    const alert = useAlert();

    useEffect(() => {
        fetchEnquiriesData();
         //eslint-disable-next-line
    }, [refetch]);

    const fetchEnquiriesData = async () => {
        try {
            let response = await ApiRequest.fetchEnquiriesData();
            const { data } = response;

            if (data.data) {
                let count = 1;
                data.data.enquiries.forEach((enquiry) => {
                    enquiry.id = count; count++
                    enquiry.contactName = enquiry.contactPerson.name;
                    enquiry.contactEmail = enquiry.contactPerson.email;
                    enquiry.hotelName = enquiry.hotel.name;
                    enquiry.hotelEmail = enquiry.hotel.email;
                    enquiry.hotelAddress = enquiry.hotel.address;
                    enquiry.hotelPhone = enquiry.hotel.phoneNumber;
                    enquiry.hotelWebsite = enquiry.hotel.website;
                })
                setAllData(data.data.enquiries);
            }
            setLoading(false)
        } catch (error) {
            if (error && error.response) {
                alert.error(error.response.data.validations);
                setLoading(false);
            }
        }
    }

    return (
        <div>
            { loading ? <Loader /> : <Table columns={columns} rows={allData} />}
            <Modal
                style={customStyles}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel='Example Modal'>
                <div className='settingsSpacing'>
                    <Typography variant="h5" component="h2" >
                        Approve Hotel
                    </Typography>
                </div>
                <div className='settingsSpacing'>
                    <Typography variant="subtitle1" className='settingsSpacing'>
                        Would you like to approve {hotel.hotelName} and send verification email to {hotel.hotelEmail} ?
                    </Typography>
                </div>
                <div className='serviceChargeButtonContainers'>
                    <div className='settingsSubmitButton'>
                        <Button
                            onClick={handleSubmit}
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={modalLoading}
                        >
                            Confirm
                            {modalLoading ? <CircularProgress size={20} /> : ''}

                        </Button>
                    </div>
                    <div>
                        <ButtonComponent text={'Cancel'} onClick={closeModal} />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Enquiries
