import React, { useState, useEffect } from 'react';
import Table from '../../components/Table/Table';
import { useAlert } from 'react-alert';
import Loader from '../../components/Loader/Loader';
import ApiRequest from '../../services/apiRequest';
import Button from "@material-ui/core/Button";
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import ButtonComponent from '../../components/Button/Button';
import Modal from 'react-modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField'; 
import FormControl from '@material-ui/core/FormControl';

const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
}))(Button);

export const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        padding: '1.875rem',
        right: 'auto',
        width: Boolean(window.navigator.userAgent.match(
            /Mobi|Android|webOS|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i
        )) ? '24rem' : '30rem',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: '3px 4px 27px rgba(0, 0, 0, 0.07)',
        border: '',
    },
};

function Hotels() {
    const [loading, setLoading] = useState(true);
    const [allData, setAllData] = useState({});
    const [refetch, setRefetch] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalIsAccountOpen, setIsAccountOpen] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [hotel, setHotel] = useState({});
    const [accNumber, setAccNumber] = useState('');
    const [accName, setAccName] = useState('');
    const [bank, setBank] = useState(null);
    const [bankList, setBankList] = useState([]);

    const alert = useAlert();

    useEffect(() => {
        fetchHotelData();
        //eslint-disable-next-line
    }, [refetch]);

    const fetchBanks = async () => {
        try {
            let response = await ApiRequest.fetchBanks();
            const { data } = response;

            if (data.data) {
                setBankList(data.data);
            }
        } catch (error) {
            if (error && error.response) {
                console.log(error.response);
            }
        }
    }

    const showModal = (row) => {
        setHotel(row);
        setIsOpen(true);
    }

    const showAccountModal = (row) => {
        setHotel(row);
        setAccNumber(row.accountInfo?.accountNumber);
        setAccName(row.accountInfo?.accountName);

        if(row.accountInfo) {
            setBank(bankList.find((list) => list.code === row.accountInfo.bankCode));
        } else {
            setBank(null)
        }

        setIsAccountOpen(true);
    }

    const fetchHotelData = async () => {
        setLoading(true);
        try {
            await fetchBanks();
            let response = await ApiRequest.fetchHotelData();
            const { data } = response;

            if (data.data) {
                setAllData(data.data.hotels);
            }
            setLoading(false)
        } catch (error) {
            if (error && error.response) {
                alert.error(error.response.data.validations);
                setLoading(false);
            }
        }
    }

    const handleSubmitAccount = async () => {
        if(!bank.name) {
            alert.error('Bank field cannot be empty');
            return;
        }

        if(!accName) {
            alert.error('Account Name field cannot be empty');
            return;
        }

        if(!accNumber) {
            alert.error('Account Number field cannot be empty');
            return;
        }

        const build = {
            accountName: accName,
            accountNumber: accNumber,
            bankName: bank.name,
            bankCode: bank.code
        }

        try {
            setModalLoading(true);
            let response = await ApiRequest.updateHotelAccount(build, hotel.id);
            const { data } = response;

            if (data.message) {
                alert.success(data.message);
                setIsAccountOpen(false);
                setRefetch(!refetch);
            }

            setModalLoading(false)
        } catch (error) {
            if (error && error.response) {
                alert.error(error.response.data.validations);
                setModalLoading(false);
            }
        }


        console.log(accNumber, accName, bank);
    }

    function closeModal() {
        setIsOpen(false);
        setIsAccountOpen(false);
        setHotel({})
    }

    const handleSubmit = async () => {
        const build = {
            id: hotel.id
        }

        try {
            setModalLoading(true);
            let response = await ApiRequest.toggleHotel(build);
            const { data } = response;

            if (data.message) {
                alert.success(data.message);
                setIsOpen(false);
                setRefetch(!refetch);
            }

            setModalLoading(false)
        } catch (error) {
            if (error && error.response) {
                alert.error(error.response.data.validations);
                setModalLoading(false);
            }
        }
    }

    const columns = [
        { field: 'id', headerName: 'HOTEL ID', width: 250 },
        { field: 'hotel_name', headerName: 'HOTEL NAME', width: 250 },
        { field: 'bookings_count', headerName: 'BOOKINGS COUNT', width: 130 },
        { field: 'no_of_rooms_loaded', headerName: 'ROOMS LOADED', width: 130 },
        {
            field: 'address',
            headerName: 'ADDRESS',
            width: 300,
        },
        { field: 'completion_progress', headerName: 'COMPLETED STATUS', width: 130 },
        {
            field: 'hidden', headerName: 'ACTION', width: 200, renderCell: (params) => {
                if (params.value === true) {
                    return <Button
                        variant="contained"
                        color="secondary"
                        size="medium"
                        onClick={() => showModal(params.row)}
                        style={{ marginLeft: 16 }}
                    >
                        DEACTIVATE
                    </Button>
                } else {
                    return <ColorButton
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={() => showModal(params.row)}
                        style={{ marginLeft: 16 }}
                    >
                        ACTIVATE
                    </ColorButton>
                }
            }
        },
        {
            field: 'account', headerName: 'UPDATE ACCOUNT', width: 250, renderCell: (params) => {
                return <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={() => showAccountModal(params.row)}
                style={{ marginLeft: 16 }}
            >
                UPDATE ACCOUNT
            </Button>
            }
        },
    ];

   

    return (
        <div>
            { loading ? <Loader /> : <div>
                <Table columns={columns} rows={allData} />
                <Modal
                    style={customStyles}
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel='Example Modal'>
                    <div className='settingsSpacing'>
                        <Typography variant="h5" component="h2" >
                            Delete Discount
                    </Typography>
                    </div>
                    <div className='settingsSpacing'>
                        <Typography variant="subtitle1" className='settingsSpacing'>
                            Are you sure you want to change the state of {hotel.hotel_name} ?
                    </Typography>
                    </div>
                    <div className='serviceChargeButtonContainers'>
                        <div className='settingsSubmitButton'>
                            <Button
                                onClick={handleSubmit}
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={modalLoading}
                            >
                                Confirm
                            {modalLoading ? <CircularProgress size={20} /> : ''}

                            </Button>
                        </div>
                        <div>
                            <ButtonComponent text={'Cancel'} onClick={closeModal} />
                        </div>
                    </div>
                </Modal>
                <Modal
                style={customStyles}
                isOpen={modalIsAccountOpen}
                onRequestClose={closeModal}
                contentLabel='Example Modal'>
                <div className='settingsSpacing'>
                    <Typography variant="h5" component="h2" >
                        Update Account
                    </Typography>
                </div>
                <div className='settingsSpacing'>
                    <div className='settingsSpacing'>
                        <FormControl fullWidth>
                            <Autocomplete
                                onChange={(_,val) => setBank(val && val.name && val.code ? {name: val.name, code: val.code} : {})}
                                id="bankName"
                                options={ bankList }
                                value={bank}
                                getOptionLabel={(option) => option.name || ''}
                                className="mb-3"
                                required
                                renderInput={ (params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label="Bank Name"
                                    variant="outlined"
                                />
                                ) }
                            />
                        </FormControl>
                    </div>
                    <div className='settingsSpacing'>
                        <FormControl fullWidth >
                            <TextField value={accName} onChange={(e) => setAccName(e.target.value)} id="outlined-basic" label="Account Name" variant="outlined" />
                        </FormControl>
                    </div>
                    <div className='settingsSpacing'>
                        <FormControl fullWidth >
                            <TextField value={accNumber} onChange={(e) => setAccNumber(e.target.value)} id="outlined-basic" label="Account Number" variant="outlined" type='number'/>
                        </FormControl>
                    </div>
                </div>
                <div className='serviceChargeButtonContainers'>
                    <div className='settingsSubmitButton'>
                        <Button
                            onClick={handleSubmitAccount}
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={modalLoading}
                        >
                            Confirm
                            {modalLoading ? <CircularProgress size={20} /> : ''}

                        </Button>
                    </div>
                    <div>
                        <ButtonComponent text={'Cancel'} onClick={closeModal} />
                    </div>
                </div>
            </Modal>
                </div>
                }
        </div>
    )
}

export default Hotels
