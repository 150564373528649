import React, { useState, useEffect } from 'react';
import Table from '../../components/Table/Table';
import Button from "@material-ui/core/Button";
import { useAlert } from 'react-alert';
import Typography from '@material-ui/core/Typography';
import Loader from '../../components/Loader/Loader';
import ApiRequest from '../../services/apiRequest';
import ButtonComponent from '../../components/Button/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Modal from 'react-modal';
import FormControl from '@material-ui/core/FormControl';
import { validateEmail } from '../../utils/utils';
import './Admins.css';

export const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        padding: '1.875rem',
        right: 'auto',
        width: Boolean(window.navigator.userAgent.match(
            /Mobi|Android|webOS|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i
        )) ? '24rem' : '30rem',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: '3px 4px 27px rgba(0, 0, 0, 0.07)',
        border: '',
    },
};

function Admins() {
    const [loading, setLoading] = useState(true);
    const [allData, setAllData] = useState({});
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [refetch, setRefetch] = useState(false);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [admin, setAdmin] = useState({});
    const [modalDeleteIsOpen, setIsDeleteOpen] = useState(false);

    const columns = [
        { field: 'username', headerName: 'ADMIN NAME', width: 250 },
        { field: 'email', headerName: 'EMAIL', width: 250 },
        { field: 'type', headerName: 'ROLE', width: 200 },
        {
            field: 'action', headerName: 'ACTION', width: 200, renderCell: (params) => {

                if (params.row.type !== 'super_admin') {
                    return <Button
                        variant="contained"
                        color="secondary"
                        size="medium"
                        onClick={() => showModal(params.row)}
                        style={{ marginLeft: 16 }}
                        >
                        Delete
                    </Button>
                }
               
            }
        },
    ];

    const showModal = (row) => {
        setAdmin(row);
        setIsDeleteOpen(true)
    }

    function closeModal() {
        setIsOpen(false);
        setIsDeleteOpen(false)
    }

    const alert = useAlert();

    useEffect(() => {
        fetchAdminData();
         //eslint-disable-next-line
    }, [refetch]);

    const handleSubmit = async () => {
        if (username === '' || username === undefined) {
            alert.error('Username field cannot be empty');
            return;
        }

        if (email.length === 0) {
            alert.error('Email field cannot be empty');
            return;
        }

        if (!validateEmail(email)) {
            alert.error('Please enter a correct email format');
            return;
        }

        let userData = {
            email,
            name: username,
        };

        setModalLoading(true);

        try {
            let response = await ApiRequest.createAdmin(userData);
            const { data } = response;
            const { message } = data;

            if (message) {
                alert.success('Admin creation successful');
                setModalLoading(false);
                setUsername('')
                setEmail('');
                setIsOpen(false);
                setRefetch(!refetch);
            }
        } catch (error) {
            if (error && error.response) {
                alert.error(error.response.data.validations);
                setModalLoading(false);
            }
        }

    }

    const fetchAdminData = async () => {
        try {
            let response = await ApiRequest.fetchAdminData();
            const { data } = response;

            if (data.data) {
                let count = 1;
                data.data.admins.forEach((admin) => { admin.id = count; count++ })
                setAllData(data.data.admins);
            }
            setLoading(false)
        } catch (error) {
            if (error && error.response) {
                alert.error(error.response.data.validations);
                setLoading(false);
            }
        }
    }

    const handleDeleteSubmit = async () => {
        const build = {
            id: admin._id
        }

        try {
            setModalLoading(true);
            let response = await ApiRequest.deleteAdmin(build);
            const { data } = response;

            if (data.message) {
                alert.success(data.message);
                setIsDeleteOpen(false);
                setRefetch(!refetch);
            }

            setModalLoading(false)
        } catch (error) {
            if (error && error.response) {
                alert.error(error.response.data.validations);
                setModalLoading(false);
            }
        }
    }

    return (

        <div>
            {loading ? <Loader /> : <div>
                <div className='discountButtonContainer'>
                    <ButtonComponent text={'Create New Admin'} color='primary' onClick={() => setIsOpen(true)}/>
                </div>
                <Table columns={columns} rows={allData} />
            </div>}
            <Modal
                style={customStyles}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel='Example Modal'>
                <div className='settingsSpacing'>
                    <Typography variant="h5" component="h2" >
                        Add new Admin
                    </Typography>
                </div>
                <div className='settingsSpacing'> 
                    <div className='settingsSpacing'>
                        <FormControl fullWidth>
                            <TextField onChange={(e) => setUsername(e.target.value)} id="outlined-basic" label="Username" variant="outlined" />
                        </FormControl>
                    </div>
                    <div className='settingsSpacing'>
                        <FormControl fullWidth >
                            <TextField onChange={(e) => setEmail(e.target.value)} id="outlined-basic" label="Email" variant="outlined" type='email' />
                        </FormControl>
                    </div>
                </div>
                <div className='serviceChargeButtonContainers'>
                    <div className='settingsSubmitButton'>
                        <Button
                            onClick={handleSubmit}
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={modalLoading}
                        >
                            Confirm
                            {modalLoading ? <CircularProgress size={20} /> : ''}

                        </Button>
                    </div>
                    <div>
                        <ButtonComponent text={'Cancel'} onClick={closeModal} />
                    </div>
                </div>
            </Modal>
            <Modal
                style={customStyles}
                isOpen={modalDeleteIsOpen}
                onRequestClose={closeModal}
                contentLabel='Example Modal'>
                <div className='settingsSpacing'>
                    <Typography variant="h5" component="h2" >
                        Delete Admin
                    </Typography>
                </div>
                <div className='settingsSpacing'>
                    <Typography variant="subtitle1" className='settingsSpacing'>
                        Are you sure you want to delete admin with the username {admin.username} ?
                    </Typography>
                </div>
                <div className='serviceChargeButtonContainers'>
                    <div className='settingsSubmitButton'>
                        <Button
                            onClick={handleDeleteSubmit}
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={modalLoading}
                        >
                            Confirm
                            {modalLoading ? <CircularProgress size={20} /> : ''}

                        </Button>
                    </div>
                    <div>
                        <ButtonComponent text={'Cancel'} onClick={closeModal} />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Admins
