import './App.css';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import Dashboard from './pages/Dashboard/Dashboard';
import Bookings from './pages/Bookings/Bookings';
import Hotels from './pages/Hotels/Hotels';
import Enquiries from './pages/Enquiries/Enquiries';
import Admins from './pages/Admins/Admins';
import ProtectedRoute from './ProtectedRoute';
import Login from './pages/Login/Login';
import Discounts from './pages/Discounts/Discounts';
import Logout from './pages/Logout/Logout';
import Settings from './pages/Settings/Settings';
import Partners from './pages/Partners/Partners';


function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path='/login' component={Login} />
          <ProtectedRoute exact path='/' component={Dashboard} />
          <ProtectedRoute exact path='/bookings' component={Bookings} />
          <ProtectedRoute exact path='/logout' component={Logout} />
          <ProtectedRoute exact path='/hotels' component={Hotels} />
          <ProtectedRoute exact path='/settings' component={Settings} />
          <ProtectedRoute exact path='/enquiries' component={Enquiries} />
          <ProtectedRoute exact path='/admins' component={Admins} />
          <ProtectedRoute exact path='/discounts' component={Discounts} />
          <ProtectedRoute exact path='/partners' component={Partners} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
